<template>
  <r-container class="container-fluid">
    <r-row>
      <r-col class="col-12 text-center" v-if="!readonly">
        <r-btn @click.prevent="open"
               class="ps-1 color-one-text"
               outlined
               rounded
        >
          <r-icon v-html="$r.icons.plus"></r-icon>
          {{$t('reviews_new','renusify')}}
        </r-btn>
        <r-modal bottom
                 full-width
                 v-model="sheet">
          <r-card class="text-center pa-1" height="250px">
            <r-container>
            <r-form ref="form" v-model="valid">
              <r-rating
                :modelValue="item.score"
                size="large"
                v-model="item.score"
              ></r-rating>
              <r-text-area
                :label="$t('review','renusify')"
                :rules="['required','max_len:1000']"
                v-model="item.description"></r-text-area>
              <div class="d-flex h-end mt-2">
                <r-btn @click.stop="sheet = !sheet" class="color-error-text mx-1" outlined rounded>
                  {{$t('cancel','renusify')}}
                </r-btn>
                <r-btn :disabled="!valid" :loading="loading" @click.stop="save" class="color-success-text" outlined
                       rounded>
                  {{$t('send','renusify')}}
                </r-btn>
              </div>
            </r-form>
              </r-container>
          </r-card>
        </r-modal>
      </r-col>
    </r-row>

    <r-infinite-page :url="link" direction="start" v-if="show" :no-item-msg="$t('no_item_reviews')">
      <template v-slot="prop">
        <r-card :key="i" class="pa-3 ma-2" flat
                v-for="(item,i) in prop.items">
          <r-row>
            <r-col>
              <div class="subtitle-1">
                {{item.user.name}}
                <r-rating
                :modelValue="item.score"
                readonly
                size="x-small"
              ></r-rating>
              </div>
            </r-col>
            <r-col class="text-end">
              <r-time-ago :time="item.created_at" class="caption teal--text "></r-time-ago>
            </r-col>
          </r-row>

          <div class="body-1 text-pre-wrap">
            {{item.description}}
          </div>
          <div v-if="item.reply" class="body-1 text-pre-wrap ms-5 mt-2 review-reply">
            <div class="subtitle-2">{{$t('admin')}}</div>
            {{item.reply}}
          </div>
          <div class="text-end">
            <r-divider class="my-2"></r-divider>
            <r-btn @click.stop="update(item._id,'like')"
                   class="px-1 color-one-text"
                   outlined
                   rounded
                   size="small">
              {{item.likes?item.likes:0}}
              <r-icon class="mx-1" v-html="like"></r-icon>
            </r-btn>
            <r-btn @click.prevent.stop="update(item._id,'dislike')"
                   class="px-1 mx-1 color-error-text"
                   outlined
                   rounded
                   size="small">
              {{item.dislikes?item.dislikes:0}}
              <r-icon class="mx-1" v-html="dislike"></r-icon>
            </r-btn>
          </div>
        </r-card>

      </template>
    </r-infinite-page>
  </r-container>
</template>

<script>
export default {
  name: 'homeReview',
  props: {
    link: {
      type: String,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      like:'<path fill="currentColor" d="M5 9v12H1V9h4m4 12a2 2 0 0 1-2-2V9c0-.55.22-1.05.59-1.41L14.17 1l1.06 1.06c.27.27.44.64.44 1.05l-.03.32L14.69 8H21a2 2 0 0 1 2 2v2c0 .26-.05.5-.14.73l-3.02 7.05C19.54 20.5 18.83 21 18 21H9m0-2h9.03L21 12v-2h-8.79l1.13-5.32L9 9.03V19Z"/>',
      dislike:'<path fill="currentColor" d="M19 15V3h4v12h-4M15 3a2 2 0 0 1 2 2v10c0 .55-.22 1.05-.59 1.41L9.83 23l-1.06-1.06c-.27-.27-.44-.64-.44-1.06l.03-.31l.95-4.57H3a2 2 0 0 1-2-2v-2c0-.26.05-.5.14-.73l3.02-7.05C4.46 3.5 5.17 3 6 3h9m0 2H5.97L3 12v2h8.78l-1.13 5.32L15 14.97V5Z"/>',
      sheet: false,
      valid: false,
      loading: false,
      show: true,
      item: {
        score: 0,
        description: ''
      }
    }
  },
  methods: {
    open () {
      if (!this.$helper.ifHas(this.$r.store,false,'user','login')) {
        this.$storage.set('url.intended', this.$route.fullPath)
        this.$toast(this.$t('reviews.login_need'), { type: 'warning' })
        this.$router.push({ name: 'login' })
      } else {
        this.sheet = !this.sheet
      }
    },
    save () {
      this.loading = true
      this.$axios.post(this.link, this.item)
        .then(() => {
          this.loading = false
          this.sheet = false
          this.show = false
          setTimeout(() => {
            this.show = true
          }, 100)
        }, () => {
          this.loading = false
        })
    },
    update (id, type) {
      this.$axios.put(this.link + '/' + id,
        { 'for': type })
        .then(() => {
          this.show = false
          setTimeout(() => {
            this.show = true
          }, 100)
        })
    }
  }
}
</script>
<style lang="scss">
  @import "~renusify/style/include";
  .review-reply{
    padding: 5px;
    @include ltr(){
      border-left: 4px solid #0b8e6b;
    }
    @include rtl(){
      border-right: 4px solid #0b8e6b;
    }
  }
</style>