<template>
  <r-container class="single-place-page">
    <r-card class="pa-1 mb-5" v-if="place.images">
      <r-img
        :src="'/' + place.images[0]"
        :alt="place.name"
        auto-size
        :w-p-h="2"
      ></r-img>
      <h1 class="display-5 my-1 d-flex h-space-between v-baseline mx-1">
        {{ place.name }}
        <div class="d-flex v-center">
          <r-rating :model-value="place.score || 0" readonly></r-rating>
          <span class="subtitle-2 color-info-text"> {{ $t(place.type) }}</span>
        </div>
      </h1>
      <h2 class="subtitle-1 my-1 mx-1">{{ place.manager.name }}</h2>
      <p class="mx-1">
        {{ $t("address") }}: {{ place.address.state }} -
        {{ place.address.city }} - {{ place.address.address }}
      </p>
    </r-card>

    <r-row v-for="(item, special_id) in doctors" :key="special_id">
      <r-col class="col-12">
        <h2 class="display-5 color-info-text">
          {{ item[0].specialist.name }}
        </h2>
        <div class="br-btm my-1"></div>
      </r-col>
      <r-col class="col-6 md-3" v-for="doctor in item" :key="doctor._id">
        <r-card class="pa-1">
          <r-img
            :src="get_img(doctor.user.value)"
            :alt="doctor.user.name"
            auto-size
          ></r-img>
          <div class="title">
            {{ doctor.user.name }}
          </div>
          <div class="subtitle-1">
            {{ $t(doctor.education) }}
          </div>
          <div class="mb-1 mt-4">
            <r-btn
              class="color-info-text"
              outlined
              block
              :to="{
                name: 'singleDoctor',
                params: { doctor_id: doctor._id },
              }"
              >{{ $t("see_more") }}</r-btn
            >
          </div>
        </r-card>
      </r-col>
    </r-row>
    <r-row>
      <r-col class="col-12">
        <div class="display-5 mt-12">نظرات کاربران</div>
        <div class="br-btm mt-2"></div>
        <home-review :link="`reviews/home/place/${place_id}`"></home-review>
      </r-col>
    </r-row>
  </r-container>
</template>

<script>
import homeReview from "../../../../extension/review/view/homeReview";
export default {
  name: "singlePlace",
  components: { homeReview },
  props: {
    place_id: String,
  },
  data() {
    return {
      place: {
        doctors: [],
      },
    };
  },
  created() {
    this.get();
  },
  computed: {
    doctors() {
      if (!this.place.doctors) {
        return {};
      }
      let res = {};
      for (let i = 0; i < this.place.doctors.length; i++) {
        const item = this.place.doctors[i];
        if (!(item.specialist.value in res)) {
          res[item.specialist.value] = [];
        }
        res[item.specialist.value].push(item);
      }
      return res;
    },
  },
  methods: {
    get() {
      this.$axios.get("/home/places/show/" + this.place_id).then(({ data }) => {
        this.place = data;
      });
    },
    get_img(id) {
      for (let i = 0; i < this.place.users.length; i++) {
        if (this.place.users[i]._id === id) {
          return this.place.users[i].images
            ? "/" + this.place.users[i].images[0]
            : "/storage/img/site/avatar.jpg";
        }
      }
      return "/storage/img/site/avatar.jpg";
    },
  },
};
</script>

<style lang="scss">
@import "~renusify/style/include";
.single-place-page {
  .br-btm {
    width: 50px;
    border-bottom: #f5a130 2px solid;
  }
}
</style>
